import { Hero } from './Sections/Hero'
import { Approach } from './Sections/Approach'
import { Brands } from './Sections/Brands'
import { Words } from './Sections/Words'
import { Trust } from './Sections/Trust'
import { Footer } from './Sections/Footer'

function isHostAllowed() {
      if (window.location.host === "voli.uk" || window.location.host.includes("localhost")) {
            return true
      }
      return false
}

export function App() {
      if (isHostAllowed()) {
            return (
                  <>
                        <Hero />
                        <Approach />
                        <Brands />
                        <Words />
                        <Trust />
                        <Footer />
                  </>
            )
      }

      return (
            <div className="App">
                  <div className="container">
                        <h1>whoops</h1>
                  </div>
            </div>
      )
}
