import { Row, Col, Image } from "antd"
import fcaLogo from '../images/logos/fca.png'
import icoLogo from '../images/logos/ico.png'
import lloydsLogo from '../images/logos/lloyds.png'
import awsLogo from '../images/logos/aws.png'

export function Trust() {
    return (
        <div id="Trust" className="Section">
            <Row justify="center">
                <Col span={22}>
                    <p className="centered SectionSubtitle">BUILT FOR LONGEVITY</p>
                    <h2 className="centered">In Trusted Company</h2>
                </Col>
            </Row>

            <Row justify="center">
                <Col span={20}>
                    <div className="logo-wrapper">
                        <Row justify="center" align="middle" gutter={[48, 48]}>
                            <Col xs={12} sm={12} md={6}>
                                <div className="TrustCells">
                                    <Image
                                        src={fcaLogo}
                                        alt="FCA Logo"
                                        preview={false}
                                        className="trust-logo"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <div className="TrustCells">
                                    <Image
                                        src={icoLogo}
                                        alt="ICO Logo"
                                        preview={false}
                                        className="trust-logo"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <div className="TrustCells">
                                    <Image
                                        src={lloydsLogo}
                                        alt="Lloyds Logo"
                                        preview={false}
                                        className="trust-logo"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <div className="TrustCells">
                                    <Image
                                        src={awsLogo}
                                        alt="AWS Logo"
                                        preview={false}
                                        className="trust-logo"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
} 