import { Row, Col } from "antd"

export function Words() {
    return (
        <div id="Words" className="Section SectionDark">
            <Row justify="center">
                <Col xs={22} sm={16}>
                    <p className="centered SectionSubtitle" style={{ color: '#FF5722' }}>WHO WE WORK WITH</p>
                    <h2 className="centered">"Partnering with forward-thinking insurers and MGAs who share our commitment to digital transformation. Together, we're reshaping insurance for the modern age."</h2>
                </Col>
            </Row>
        </div>
    )
} 