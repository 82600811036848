import { Row, Col, Divider } from "antd"

export function Footer() {
    return (
        <div id="Footer" className="Section">
            <Row justify="center">
                <Col span={22}>
                    <Row justify="center">
                        <Col xs={22} sm={4}>
                            <div className="logo">
                                <h1 style={{ opacity: 0.2 }}>voli</h1>
                            </div>
                            <div className="footer-contact">
                                <p>2nd Floor, Northgate House<br />
                                    Upper Borough Walls<br />
                                    Bath<br />
                                    BA1 1RG</p>
                                <p>
                                    <a href="tel:01225235998">01225 235998</a><br />
                                    <a href="mailto:hello@voli.uk">hello@voli.uk</a>
                                </p>
                            </div>
                        </Col>
                        <Col xs={22} sm={{ span: 8, offset: 3 }}>
                            <div style={{ fontSize: 14, fontWeight: 400, opacity: 0.7 }}>
                                <p>© {new Date().getFullYear()} Voli Ltd. All rights reserved.</p>
                                <Divider className="DividerLight" />
                                <p>
                                    Voli Limited is authorised and regulated by the{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.fca.org.uk/"
                                    >
                                        Financial Conduct Authority
                                    </a>{' '}
                                    for General Insurance Distribution activities and as a Credit Broker.
                                    We do not charge any up-front fees for arranging credit. Reg No. 1015906.
                                </p>
                                <Divider className="DividerLight" />
                                <p>
                                    Our services are covered by the{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.financial-ombudsman.org.uk/"
                                    >
                                        Financial Ombudsman Service
                                    </a>
                                    . If you cannot settle a complaint with us, eligible complainants may be
                                    entitled to refer it to the Financial Ombudsman Service for an independent
                                    assessment. The FOS Consumer Helpline is on 0800 023 4567 and their address
                                    is: Financial Ombudsman Service, Exchange Tower, London E14 9SR.
                                </p>
                                <Divider className="DividerLight" />
                                <p>
                                    Registered in England No. 15554073 Registered Office: Suite 22 Trym Lodge,
                                    1 Henbury Road, Westbury-On-Trym, Bristol, United Kingdom, BS9 3HQ
                                </p>
                                <p style={{ opacity: 0.3 }}>v1.0.1</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
} 