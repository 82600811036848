import { Drawer } from 'antd'
import { scrollToSection } from '../utils/scroll'

export function MobileMenu({ isOpen, onClose }) {
    const menuItems = [
        { label: 'Home', section: 'HeroContainer' },
        { label: 'Approach', section: 'Approach' },
        { label: 'Brands', section: 'Brands' },
        { label: 'Trust', section: 'Trust' },
        { label: 'Contact', section: 'Footer', highlight: true },
    ]

    const handleClick = (sectionId) => {
        scrollToSection(sectionId)
        onClose()
    }

    return (
        <Drawer
            placement="right"
            onClose={onClose}
            open={isOpen}
            width="100%"
            className="mobile-menu-drawer"
            styles={{
                header: {
                    background: '#000000',
                    borderBottom: 'none'
                },
                body: {
                    padding: 0,
                    background: '#000000',
                },
                mask: {
                    background: 'rgba(0, 0, 0, 0.8)'
                }
            }}
            closeIcon={<span style={{ color: 'white', fontSize: '24px' }}>×</span>}
        >
            <div className="mobile-menu">
                {menuItems.map((item) => (
                    <button
                        key={item.section}
                        onClick={() => handleClick(item.section)}
                        className={`mobile-menu-item ${item.highlight ? 'highlight' : ''}`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
        </Drawer>
    )
} 