import { Row, Col } from "antd"

export function Approach() {
    return (
        <div id="Approach" className="Section">
            <Row justify="center">
                <Col xs={20} sm={20} md={18}>
                    <p className="SectionSubtitle">OUR APPROACH</p>
                    <h2>Why we do what we do</h2>
                </Col>
            </Row>

            <Row justify="center" style={{ margin: "50px 0" }}>
                <Col xs={22} sm={22} md={18}>
                    <Row justify="center" gutter={[{ xs: 20, sm: 32, md: 48 }, { xs: 32, sm: 32, md: 48 }]}>
                        <Col xs={22} md={8}>
                            <div className="ApproachCells">
                                <p className="approach-number">01.</p>
                                <h3 className="approach-title">Creativity.</h3>
                                <h4 className="approach-subtitle">Pioneering solutions.</h4>
                                <p className="approach-text">
                                    "We transform complex insurance needs into elegantly simple digital products, combining deep industry knowledge with innovative design thinking."
                                </p>
                            </div>
                        </Col>
                        <Col xs={22} md={8}>
                            <div className="ApproachCells">
                                <p className="approach-number">02.</p>
                                <h3 className="approach-title">Technology.</h3>
                                <h4 className="approach-subtitle">Smart AI platforms.</h4>
                                <p className="approach-text">
                                    "Our proprietary technology stack enables rapid product development and seamless integration, delivering insurance that works at digital speed."
                                </p>
                            </div>
                        </Col>
                        <Col xs={22} md={8}>
                            <div className="ApproachCells">
                                <p className="approach-number">03.</p>
                                <h3 className="approach-title">Discover.</h3>
                                <h4 className="approach-subtitle">Trusted as standard.</h4>
                                <p className="approach-text">
                                    "We embed regulatory compliance and robust security at every level, ensuring our products meet the highest standards of data protection."
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
} 