import { Row, Col } from "antd"
import { MenuOutlined } from '@ant-design/icons'
import { useState } from 'react'
import heroImage from '../images/hero.jpg'
import { scrollToSection } from '../utils/scroll'
import { MobileMenu } from '../components/MobileMenu'

export function Hero() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div id="HeroContainer" className="SectionLight" style={{ background: `url(${heroImage}) center center/cover no-repeat` }}>
            <div id="Header">
                <Row justify="center">
                    <Col span={22}>
                        <Row align="middle" style={{ padding: '20px 0' }}>
                            <Col flex="auto">
                                <div className="logo">
                                    <h1>voli</h1>
                                </div>
                            </Col>
                            <Col className="desktop-menu">
                                <Row gutter={32} align="middle">
                                    <Col>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            scrollToSection('Approach')
                                        }}>Approach</a>
                                    </Col>
                                    <Col>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            scrollToSection('Brands')
                                        }}>Brands</a>
                                    </Col>
                                    <Col>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            scrollToSection('Trust')
                                        }}>Trust</a>
                                    </Col>
                                    <Col>
                                        <button
                                            className="contact-button"
                                            onClick={() => scrollToSection('Footer')}
                                        >Contact</button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mobile-menu-trigger">
                                <button
                                    className="hamburger-button"
                                    onClick={() => setMobileMenuOpen(true)}
                                >
                                    <MenuOutlined style={{ fontSize: '24px' }} />
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="hero-content">
                <Row justify="center">
                    <Col
                        xs={{ span: 22, offset: 0 }}
                        sm={{ span: 20, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                    >
                        <h1>We build clear & direct insurance products for a digital age</h1>
                        <button
                            className="discover-button"
                            onClick={() => scrollToSection('Approach')}
                        >Discover More</button>
                    </Col>
                </Row>
            </div>

            <MobileMenu
                isOpen={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
            />
        </div>
    )
} 