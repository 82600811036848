import { Row, Col } from "antd"
import { BsShieldCheck as SheildIcon, BsTools as ToolsIcon, BsCarFront as CarIcon } from 'react-icons/bs'
import weddingBg from '../images/brands/wedding.jpg'
import tradesmenBg from '../images/brands/tradesmen.jpg'
import motorBg from '../images/brands/motor.jpg'

export function Brands() {
    const cardStyle = (bgImage) => ({
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bgImage})`,
        filter: 'grayscale(50%)',
        color: 'white'
    })

    return (
        <div id="Brands" className="Section SectionLight">
            <Row justify="center">
                <Col span={22}>
                    <p className="centered SectionSubtitle">OUR BRANDS</p>
                    <h2 className="centered">Some of the things we do</h2>
                </Col>
            </Row>

            <Row justify="center">
                <Col span={23}>
                    <Row justify="center" gutter={[24, 24]}>
                        <Col xs={22} sm={12} md={8}>
                            <div className="brand-card" style={cardStyle(weddingBg)}>
                                <div className="brand-icon">
                                    <SheildIcon size={32} />
                                </div>
                                <h3>Wedding Insurance</h3>
                            </div>
                        </Col>
                        <Col xs={22} sm={12} md={8}>
                            <div className="brand-card" style={cardStyle(tradesmenBg)}>
                                <div className="brand-icon">
                                    <ToolsIcon size={32} />
                                </div>
                                <h3>Tradesmen Tools</h3>
                            </div>
                        </Col>
                        <Col xs={22} sm={24} md={8}>
                            <div className="brand-card" style={cardStyle(motorBg)}>
                                <div className="brand-icon">
                                    <CarIcon size={32} />
                                </div>
                                <h3>Motor Ancillary</h3>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
} 